import wrapperbg from "./narayanmunitech/images/wrapper-bg.jpg";
import slide01 from "./narayanmunitech/images/slide-01.jpg";
import slide02 from "./narayanmunitech/images/slide-02.jpg";
import slide03 from "./narayanmunitech/images/slide-03.jpg";
import slide04 from "./narayanmunitech/images/slide-04.jpg";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message"
import { useEffect, useState } from "react";

function App() {
  const { register, handleSubmit,  formState: { errors } } = useForm(); 

  const [isLoading, setIsLoading] = useState(true)

useEffect(() => {
  setTimeout(() => {
    setIsLoading(false)
  }, 4000);
}, [])


  const onSubmit = (data) => {
     console.log("🚀 ~ file: App.js:13 ~ onSubmit ~ data:", data)
    //  Regex expression to remove all characters which are NOT alphanumeric 
     let number = data.phone.replace(/[^\w\s]/gi, "").replace(/ /g, "");

     // Appending the phone number to the URL
       let url = `https://web.whatsapp.com/send?phone=919081891871`;
 
     // Appending the message to the URL by encoding it
       url += `&text=${JSON.stringify(data)}&app_absent=0`;

       window.open(url)
  };

  return (
    <>
     
     
    </>
  );
}

export default App;
